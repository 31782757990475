'use strict';
/**
 * The job list
 * @param {Object} $scope - the local scope object
 * @param {Object} $state - the state object
 * @param {Object} DataManager - the DataManager API
 * @param {Object} Notification - The Notification util
 */
angular.module('cpformplastApp.jobs')
    .controller('jobManagementController', function($scope, $state, DataManager, Notification, $modal, Modal, Auth  ) {

  $scope.jobs = [];
  $scope.isPreviewMode = false

  $scope.jobTableButtons = [{
    'icon':'plus',
    'title':'Ajouter',
    'function': () => {
      $state.go('jobs/job-management/job-creation');
    }
  }];

  const PreviewModeOnlyRoles = ["salesmen_dept"]

  $scope.jobTableStructure = [
    {
      'title':'#Job',
      'value':'number',
      'filter':'alpha'
    },
    {
      'title':'#Client',
      'value':'_itemClientName',
      'filter':'alpha'
    },
    {
      'title':'#Moule',
      'value':'_itemMold',
      'filter':'alpha'
    },
    {
      'title':'Moule',
      'value':'_itemName',
      'filter':'alpha'
    },
    {
      'title':'Matériel',
      'value':'_itemMateriel',
      'filter':'alpha'
    },
    {
      'title':'#PO',
      'value':'po',
      'filter':'alpha'
    },
    {
      'title':'#Code',
      'value':'_itemCode',
      'filter':'alpha'
    },
    {
      'title':'archive',
      'value':'archive',
      'filter':'alpha'
    }
  ];
  $scope.setIsPreviewMode = function () {
    Auth.getCurrentUser(function(user, err) {

      $scope.isPreviewMode = PreviewModeOnlyRoles.findIndex( x => x === user.role) !== -1

      if($scope.isPreviewMode) $scope.jobTableButtons = [];
    })
  }

  /**
   * Initialisation function
   */
  $scope.init = function() {
    DataManager.fetchJobs(true).then(data => {
      $scope.jobs = data;
      $scope.increaseJob();
      $scope.setIsPreviewMode();

    })
    .catch(err => {
      console.log(err);
      Notification.error('Un probème est survenu lors du chargement des jobs');
    });
  };

  /**
   * Link to go to the category edition page
   * @param {Object} job - the job to edit
   */
  $scope.selectJob = function(job) {
    var modalInstance = $modal.open({
      animation: true,
      templateUrl: '../../components/modal/info/info-job-modal.html',
      controller: 'InfoJobModalController',
      scope: $scope,
      resolve:{
        job: function () {
          return job;
        },
        item: function () {
          return job.item;
        },
      }
    });
  };

  /**
   * This function is use to increase the job element with
   * the item object to use in the custom search table
   */
  $scope.increaseJob = function(){

    for(let i = 0; i < $scope.jobs.length; i++){
      $scope.jobs[i]["_itemCode"] = $scope.jobs[i].item.code;
      $scope.jobs[i]["_itemName"] = $scope.jobs[i].item.name;
      $scope.jobs[i]["_itemClient"] = $scope.jobs[i].item.client;
      $scope.jobs[i]["_itemMold"] = $scope.jobs[i].item.mold;
      $scope.jobs[i]["_itemCategoryName"] = $scope.jobs[i].item.category.name;
      $scope.jobs[i]["_itemCategoryDimension"] = $scope.jobs[i].item.category.dimension;
      $scope.jobs[i]["_itemMateriel"] = $scope.jobs[i].item.category.dimension + ' ' + $scope.jobs[i].item.category.name;
      $scope.jobs[i]["_itemClientName"] = $scope.jobs[i].item.client ? $scope.jobs[i].item.client.name : '';
      for(let j = 0; j < $scope.jobs[i].subjobs.length; j++) {
        $scope.jobs[i].subjobs[j].date = new Date($scope.jobs[i].subjobs[j].date)
      }
    }

  };

  $scope.init();

});
